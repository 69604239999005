<template>
  <div class="lb-nav-content-right">
    <NuxtLinkLocale v-if="status === 'unauthenticated'" href="/login" class="flex items-center space-x-2.5 p-2" >
      <LucideUserCircle class="h-5 w-5 sm:h-4 sm:w-4" />
      <span class="hidden sm:block text-base font-medium whitespace-nowrap">{{ $t('navigation.my-account') }}</span>
    </NuxtLinkLocale>
    
    <template v-else-if="status === 'authenticated'">
      <UserDropdownMenu class="flex items-center">
        <template #icon>
          <LucideUserCircle class="h-5 w-5 sm:h-4 sm:w-4" />
        </template>
        <span class="hidden sm:block max-w-[120px] truncate">{{ firstName }}</span>
      </UserDropdownMenu>
    </template>

    <!-- Sepet Bölümü-->
    <ShoppingCartMenu class="inline group order-6 leading-3" :isTopBasketCount="true">
      <div class="flex items-center justify-center space-x-2.5 p-1.5 rounded-md">
        <LucideLoader2 class="h-5 w-5 sm:h-4 sm:w-4 lg:m-0 mx-2.5 animate-spin" v-if="addCartLoading" />
        <LucideShoppingBasket class="h-5 w-5 sm:h-4 sm:w-4" v-else />
        <span class="hidden sm:block text-base font-medium whitespace-nowrap" v-if="basketTotal">{{basketTotalText}}</span>
        <span class="hidden sm:block text-base font-medium whitespace-nowrap" v-else>{{ $t('navigation.basket') }}</span>
      </div>
    </ShoppingCartMenu>
  </div>
</template>

<script setup>

const {status, data} = await useAuth()
const {isWholesaleUser} = await useCustomer()

const {
  basketTotal,
  basketLoading,
  addCartLoading,
  basketCurrency,
  basketTotalText,
} = await useBasket()

const firstName = computed(() => (unref(data)?.user?.name).split(' ').slice(0, -1).join(' ') ? (unref(data)?.user?.name).split(' ').slice(0, -1).join(' ') : (unref(data)?.user?.name).split('@').slice(0, -1).join(' '))

</script>

<style lang="scss">
.lb-nav-content-right {
  @apply flex items-center justify-end gap-0 lg:gap-5 order-4 col-span-2;
}
</style>